import Login from '@/views/auth/Login.vue'
import Logout from '@/views/auth/Logout.vue'
import Dashboard from '@/views/Dashboard.vue'

import { h } from 'vue'
import { RouterView } from 'vue-router'

// All routes are protected by default
// meta: { auth: false } - Authentication disabled
// meta: { auth: 'guest' } - Only for Guests, Auth users are redirected to Dashboard
export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login', auth: 'guest' }
  },
  {
    path: '/password/forgot',
    name: 'ForgotPassword',
    meta: { title: 'Forgot Password', auth: 'guest' },
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/auth/password/Forgot.vue')
  },
  {
    path: '/password/reset',
    name: 'ResetPassword',
    meta: { title: 'Reset Password', auth: 'guest' },
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/auth/password/Reset.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: 'Register', auth: 'guest' },
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue')
  },
  {
    path: '/register-confirmation',
    name: 'RegisterConfirm',
    meta: { title: 'Register Confirmation', auth: 'guest' },
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/RegisterConfirm.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { title: 'Logout' }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { title: 'Dashboard' }
  },
  {
    path: '/machine/:id',
    name: 'Machine',
    component: Dashboard,
    meta: { title: 'Machine' }
  },
  {
    path: '/logs',
    name: 'Logs',
    meta: { title: 'Machines Logs' },
    component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue')
  },
  {
    path: '/status',
    name: 'Status',
    meta: { title: 'Status' },
    component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue')
  },
  {
    path: '/reports',
    name: 'ReportsIndex',
    component: { render: () => h(RouterView) },
    redirect: { name: 'ReportsRange' },
    children: [
      {
        path: 'range',
        name: 'ReportsRange',
        meta: { title: 'Reports' },
        component: () => import(/* webpackChunkName: "reports-range" */ '../views/reports/Range.vue')
      },
      {
        path: 'annual',
        name: 'ReportsAnnual',
        meta: { title: 'Reports' },
        component: () => import(/* webpackChunkName: "reports-annual" */ '../views/reports/Annual.vue')
      },
      {
        path: 'cashout',
        name: 'ReportsCashout',
        meta: { title: 'Reports' },
        component: () => import(/* webpackChunkName: "reports-cashout" */ '../views/reports/Cashout.vue')
      },
      {
        path: 'hourly',
        name: 'ReportsHourly',
        meta: { title: 'Reports' },
        component: () => import(/* webpackChunkName: "reports-hourly" */ '../views/reports/Hourly.vue')
      },
      {
        path: 'data-usage',
        name: 'ReportsDataUsage',
        meta: { title: 'Data Usage' },
        component: () => import(/* webpackChunkName: "reports-data-usage" */ '../views/reports/DataUsage.vue')
      },
    ]
  },

  {
    path: '/realtime',
    name: 'RealTimeIndex',
    component: { render: () => h(RouterView) },
    redirect: { name: 'ReportsRealTime' },
    children: [
      {
        path: ':machineId?',
        name: 'ReportsRealTime', // Kept Reports prefix to not break linking
        meta: { title: 'Hourly Report' },
        component: () => import(/* webpackChunkName: "reports-real-time" */ '../views/realtime/RealTime.vue')
      },
      {
        path: 'hourly/:machineId',
        name: 'ReportsRealTimeHourly',
        meta: { title: 'Hourly Report' },
        component: () => import(/* webpackChunkName: "reports-real-time-hourly" */ '../views/realtime/Hourly.vue')
      },
    ]
  },

  {
    path: '/settings',
    name: 'SettingsIndex',
    component: { render: () => h(RouterView) },
    redirect: { name: 'SettingsMachinesIndex' },
    meta: { title: 'Settings' },
    children: [
      {
        path: 'machines',
        name: 'SettingsMachines',
        component: { render: () => h(RouterView) },
        children: [
          {
            path: '',
            name: 'SettingsMachinesIndex',
            meta: { title: 'Settings' },
            component: () => import(/* webpackChunkName: "settings-machines-index" */ '../views/settings/machines/Index.vue')
          },
          {
            path: 'create',
            name: 'SettingsMachinesCreate',
            meta: { title: 'Settings - Machine Create' },
            component: () => import(/* webpackChunkName: "settings-machines-create" */ '../views/settings/machines/Create.vue')
          },
          {
            path: ':id/edit',
            name: 'SettingsMachinesEdit',
            meta: { title: 'Settings - Machine Edit' },
            component: () => import(/* webpackChunkName: "settings-machines-edit" */ '../views/settings/machines/Edit.vue')
          },
          {
            path: ':machineId/cameras/create',
            name: 'SettingsMachinesCamerasCreate',
            meta: { title: 'Settings - Machine Cameras' },
            component: () => import(/* webpackChunkName: "settings-cameras-show" */ '../views/settings/cameras/Create.vue')
          },
          {
            path: ':machineId/cameras/:id/edit',
            name: 'SettingsMachinesCamerasEdit',
            meta: { title: 'Settings - Machine Cameras' },
            component: () => import(/* webpackChunkName: "settings-cameras-edit" */ '../views/settings/cameras/Edit.vue')
          },
          {
            path: ':machineId/profile/edit',
            name: 'SettingsMachinesProfileEdit',
            meta: { title: 'Settings - Machine Profile' },
            component: () => import(/* webpackChunkName: "settings-machines-profile-edit" */ '../views/settings/machine-profile/Edit.vue')
          },
          {
            path: ':machineId/commands',
            name: 'SettingsMachinesCommands',
            meta: { title: 'Settings - Admin Command' },
            component: () => import(/* webpackChunkName: "settings-machines-commands" */ '../views/settings/Commands.vue')
          },
          {
            path: ':machineId/users',
            name: 'SettingsMachinesUsers',
            meta: { title: 'Settings - Machine Users' },
            component: () => import(/* webpackChunkName: "settings-machines-users-index" */ '../views/settings/users/Index.vue')
          },
          {
            path: ':machineId/images',
            name: 'SettingsMachinesImages',
            meta: { title: 'Settings - Machine Images' },
            component: () => import(/* webpackChunkName: "settings-machines-users-index" */ '../views/settings/images/Index.vue')
          },
          {
            path: ':machineId/images/create',
            name: 'SettingsMachinesImagesCreate',
            meta: { title: 'Settings - Add Machine Image' },
            component: () => import(/* webpackChunkName: "settings-machines-users-index" */ '../views/settings/images/Create.vue')
          },
          {
            path: ':machineId/images/edit/:imageId',
            name: 'SettingsMachinesImagesEdit',
            meta: { title: 'Settings - Edit Machine Image' },
            component: () => import(/* webpackChunkName: "settings-machines-users-index" */ '../views/settings/images/Edit.vue')
          }
        ]
      },
      {
        path: 'users',
        name: 'SettingsUsers',
        component: { render: () => h(RouterView) },
        children: [
          {
            path: '',
            name: 'SettingsUsersIndex',
            component: () => import(/* webpackChunkName: "settings-users-index" */ '../views/settings/users/Index.vue')
          },
          {
            path: ':id',
            name: 'SettingsUsersShow',
            component: () => import(/* webpackChunkName: "settings-users-show" */ '../views/settings/users/Show.vue')
          },
          {
            path: ':id/message',
            name: 'SettingsUsersMessage',
            component: () => import(/* webpackChunkName: "settings-users-message" */ '../views/settings/users/Message.vue')
          },
        ]
      },
      {
        path: 'disabled-owners',
        name: 'SettingsDisabledOwners',
        component: { render: () => h(RouterView) },
        children: [
          {
            path: '',
            name: 'SettingsDisabledOwnersIndex',
            component: () => import(/* webpackChunkName: "settings-disabled-owners-index" */ '../views/settings/disabled-owners/Index.vue')
          },
          {
            path: 'create',
            name: 'SettingsDisabledOwnersCreate',
            component: () => import(/* webpackChunkName: "settings-disabled-owners-create" */ '../views/settings/disabled-owners/Create.vue')
          },
        ]
      },
      {
        path: 'announcements',
        name: 'SettingsAnnouncements',
        component: { render: () => h(RouterView) },
        children: [
          {
            path: '',
            name: 'SettingsAnnouncementsIndex',
            component: () => import(/* webpackChunkName: "settings-announcements-index" */ '../views/settings/announcements/Index.vue')
          },
          {
            path: 'create',
            name: 'SettingsAnnouncementsCreate',
            component: () => import(/* webpackChunkName: "settings-announcements-create" */ '../views/settings/announcements/Form.vue')
          },
          {
            path: ':id/edit',
            name: 'SettingsAnnouncementsEdit',
            component: () => import(/* webpackChunkName: "settings-announcements-edit" */ '../views/settings/announcements/Form.vue')
          },
        ]
      },
      {
        path: 'mv-cradle',
        name: 'SettingsMVCradleUpdater',
        component: () => import(/* webpackChunkName: "mv-cradle-updater" */ '../views/MVCradleUpdater.vue')
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
      },
      {
        path: 'tticlub',
        name: 'TTIClub',
        component: () => import(/* webpackChunkName: "tticlub" */ '../views/TTIClub.vue')
      },
      {
        path: 'data-usage',
        name: 'SettingsDataUsageIndex',
        meta: { title: 'Data Usage Admin' },
        component: () => import(/* webpackChunkName: "settings-data-usage" */ '../views/settings/data-usage/Index.vue')
      },
    ]
  },

  {
    path: '/scheduler',
    name: 'SchedulerIndex',
    component: { render: () => h(RouterView) },
    redirect: { name: 'SchedulerSchedulesIndex' },
    children: [
      {
        path: 'machines',
        name: 'SchedulerMachinesIndex',
        meta: { title: 'Scheduler' },
        component: () => import(/* webpackChunkName: "scheduler-machines-index" */ '../views/scheduler/machines/Index.vue')
      },
      {
        path: 'schedules',
        name: 'SchedulerSchedules',
        meta: { title: 'Scheduler' },
        component: { render: () => h(RouterView) },
        children: [
          {
            path: '',
            name: 'SchedulerSchedulesIndex',
            meta: { title: 'Scheduler' },
            component: () => import(/* webpackChunkName: "scheduler-schedules-index" */ '../views/scheduler/schedules/Index.vue'),
          },
          {
            path: 'create',
            name: 'SchedulerSchedulesCreate',
            meta: { title: 'Scheduler - Add Schedule' },
            component: () => import(/* webpackChunkName: "schedules-create" */ '../views/scheduler/schedules/Create.vue')
          },
          {
            path: ':id/edit',
            name: 'SchedulerSchedulesEdit',
            meta: { title: 'Scheduler - Edit Schedule' },
            component: () => import(/* webpackChunkName: "schedules-edit" */ '../views/scheduler/schedules/Edit.vue')
          },
        ]
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/404.vue')
  },
]
