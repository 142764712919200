<template>
  <div
    v-if="showModeToggler || showColumnVisibility"
    class="flex justify-between items-center relative mb-2 sm:mb-4"
  >
    <mode-toggler
      v-if="showModeToggler"
      :mode="state.mode"
      @change="updateMode"
    />
    <columns-toggler
      v-if="showColumnVisibility"
      :columns="tableData[0].columns"
      @change="updateHiddenColumns"
    />
  </div>
  <div class="flex flex-col">
    <div
      class="-my-2 sm:-mx-6 lg:-mx-8"
      :class="{ 'overflow-x-auto': state.mode === 'swipe' }">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden border-b border-big-stone border-opacity-20 sm:rounded-lg">
          <table class="w-full">
            <table-head
              :columns="getVisibleColumns(tableData[0].columns)"
              :default-sort="state.sort"
              @sort="(sort) => state.sort = sort"
            />

            <table-body
              :items="tableData"
              :mode="state.mode"
              :columns-per-table="state.columnsPerTable"
              :show-accessory-row="showAccessoryRow"
            />

            <table-footer
              v-if="showFooter"
              :columns="getVisibleColumns(tableData[0].columns)"
              :data="tableData"
            />
          </table>
        </div>
      </div>
    </div>
  </div>

  <slot name="footer" />
</template>

<script>
import { computed, onBeforeUnmount, onMounted, reactive, markRaw, watch } from 'vue'
import http from '@/services/http.js'
import TextField from '@/components/datatables/Fields/TextField.vue'
import ColumnsToggler from '@/components/datatables/ColumnsToggler.vue'
import ModeToggler from '@/components/datatables/ModeToggler.vue'
import TableHead from '@/components/datatables/TableHead.vue'
import TableBody from '@/components/datatables/TableBody.vue'
import TableFooter from '@/components/datatables/TableFooter.vue'

export default {
  name: 'DatatablesTable',

  components: {
    ColumnsToggler,
    ModeToggler,
    TableHead,
    TableBody,
    TableFooter,
  },

  props: {
    id: {
      type: [String, Number],
      default: null
    },
    columns: {
      type: Array,
      required: true
    },
    sortBy: {
      type: Object,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    showColumnVisibility: {
      type: Boolean,
      default: true,
    },
    showModeToggler: {
      type: Boolean,
      default: true,
    },
    showAccessoryRow: {
      type: Boolean,
      default: false,
    },
    defaultMode: {
      type: String,
      default: 'responsive',
    }
  },

  setup(props) {
    const state = reactive({
      sort: props.sortBy,
      mode: props.defaultMode,
      columnsPerTable: null,
      hiddenColumns: []
    })

    const tableData = computed(() => {
      const data = []

      props.columns.forEach((row, rowIndex) => {
        row.columns.forEach((column) => {
          let isSortable = true

          if (column.sortable !== undefined) {
              isSortable = column.sortable
          }

          if (! ['string', 'number'].includes(typeof column.value) && column.value !== null) {
              isSortable = false
          }

          const columnData =  {
            ...column,
            isVisible: ! state.hiddenColumns.includes(column.key),
            isHidedenByResize: false,
            component: markRaw(column.component || TextField),
            sortable: isSortable,
          }

          if (data[rowIndex]) {
            data[rowIndex].columns.push(columnData)
          } else {
            data.push({
              id: rowIndex,
              class: row.className,
              columns: [columnData],
            })
          }
        })
      })

      return data.sort(function (a, b) {
        const x = (state.sort.direction === 'ASC' ? a.columns : b.columns).find((column) => column.key === state.sort.key).value
        const y = (state.sort.direction === 'ASC' ? b.columns : a.columns).find((column) => column.key === state.sort.key).value

        return isNaN(x - y) ? String(x).localeCompare(y) : x - y
      })
    })

    const getVisibleColumns = (columns) => {
      if (! state.columnsPerTable) {
        return columns
      }

      if (state.mode === 'swipe') {
        return columns.filter((value) => value.isVisible)
      }

      return columns.filter((value) => ! value.isHidedenByResize && value.isVisible)
    }

    const resizeHandler = () => {
      const tableWidth = document.body.clientWidth

      if (tableWidth <= 350) {
        state.columnsPerTable = 2
      } else {
        state.columnsPerTable = Math.round(tableWidth / 110 * 0.9)
      }

      tableData.value.forEach((row) => {
        row
          .columns
          .filter((data) => data.isVisible)
          .sort((first, second) => first.priority - second.priority)
          .forEach((data, index) => {
            data.isHidedenByResize = index >= state.columnsPerTable
          })
      })
    }

    const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
    let localStorageKey = ''
    if (props.id) {
      localStorageKey = `datatable_${props.id}`
      const datatablesConfigFromLocalStorage = localStorage.getItem(localStorageKey)

      if (datatablesConfigFromLocalStorage) {
        const ls = JSON.parse(datatablesConfigFromLocalStorage)
        state.hiddenColumns = ls.hidden_columns
        state.mode = ls.mode
      }
    }

    const getHiddenColumns = async () => {
      const { data } = await http.get(`/user/profile/configuration/${props.id}/${deviceType}`)

      state.hiddenColumns = data.hidden_columns
      state.mode = data.mode

      saveConfigToLocalStorage()
    }

    const saveConfigToLocalStorage = () => {
      if (!props.id) {
        return
      }

      const payload = {
        hidden_columns: state.hiddenColumns,
        mode: state.mode,
      }

      http.post('/user/profile/configuration', {
        key: props.id,
        device: deviceType,
        ...payload
      })

      localStorage.setItem(
        localStorageKey,
        JSON.stringify(payload)
      )
    }

    const updateHiddenColumns = (columns) => {
      state.hiddenColumns = columns

      saveConfigToLocalStorage()
    }

    const updateMode = (mode) => {
      state.mode = mode

      saveConfigToLocalStorage()
    }

    onMounted(() => {
      resizeHandler()
    })

    window.addEventListener('resize', resizeHandler)

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    watch(
      () => tableData.value,
      () => {
        resizeHandler()
      }
    )

    if (props.id) {
      localStorageKey = `datatable_${props.id}`
      const datatablesConfigFromLocalStorage = localStorage.getItem(localStorageKey)

      if (datatablesConfigFromLocalStorage) {
        const ls = JSON.parse(datatablesConfigFromLocalStorage)
        state.hiddenColumns = ls.hidden_columns
        state.mode = ls.mode
      } else {
        // Only fetch from server if no local storage config
        getHiddenColumns()
      }
    }

    return {
      state,
      tableData,
      getVisibleColumns,
      updateHiddenColumns,
      updateMode,
    }
  },
}
</script>
