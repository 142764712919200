<template>
  <button @click="open()" type="button" :disabled="disabled" :class="{ 'cursor-not-allowed': disabled }" class="btn-plc btn-white" data-toggle="modal" data-target="#loadBagsModal">Load Bags</button>

  <modal-confirmation :show-icon="false" v-model:isOpen="state.isOpen" :isLoading="state.isLoading" @confirmed="submit">
    <template #title>
      Load Bags
    </template>

    <slot name="body">
      <h4 class="block text-sm font-medium leading-5 text-gray-700">
        Predefined bags quantity
      </h4>
      <div class="space-x-2 mt-1">
        <template v-for="(quantity, index) in predefinedQuantityButtons" :key="index">
          <button
            @click="setQuantity(quantity)"
            type="button"
            :class="{
              'border-big-stone text-white bg-big-stone': form.quantity === quantity,
              'border-gray-300 shadow-sm text-gray-700 bg-white transition hover:bg-gray-200': form.quantity !== quantity,
            }"
            class="inline-flex items-center px-3 py-2 border leading-4 font-medium rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{ quantity }}
          </button>
        </template>
      </div>
      <div class="mt-4">
        <label for="quantity" class="block text-sm font-medium leading-5 text-gray-700">Custom bags quantity</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input id="quantity" ref="input" v-model="form.quantity" required type="number" pattern="[0-9]*" class="block w-full border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out" placeholder="Enter Bags Count">
        </div>
      </div>
    </slot>

    <template #confirmation-button>
      Add Bags
    </template>
  </modal-confirmation>
</template>

<script>
import { reactive, ref, watch } from 'vue'
import { handler } from './actionHandler.js'
import ModalConfirmation from '@/components/modals/Confirmation.vue'

export default {
  name: 'machines-actions-load-bags',

  components: {
    ModalConfirmation,
  },

  props: ['machineId', 'disabled'],

  setup(props) {
    const input = ref()
    const {
      handle,
      open,
      state,
      close,
    } = handler()


    const form = reactive({
      quantity: null
    })

    const predefinedQuantityButtons = [125, 250, 500]

    const submit = async () => {
      await handle('Load Bags', 'load_bags', parseInt(props.machineId), {
        amount: parseInt(form.quantity),
      })
    }

    const setQuantity = (quantity) => {
      form.quantity = quantity
    }

    watch(
      () => state.isOpen,
      (isOpen) => isOpen ? setTimeout(() => input.value.focus(), 250) : null
    )

    return {
      state,
      form,
      open,
      close,
      submit,
      setQuantity,
      predefinedQuantityButtons,
      input
    }
  }
}
</script>
