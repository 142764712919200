<template>
  <layout>
    <div class="mt-2 grid md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5 gap-3 sm:gap-4 xxl:gap-8">
      <dashboard-card
        title="Water & Ice Sales"
        :amount="statistics.totalRevenue"
      />
      <dashboard-card
        title="Ice Sales"
        :amount="statistics.iceSales"
      />
      <dashboard-card
        title="Water Sales"
        :amount="statistics.waterSales"
      />
      <dashboard-card
        title="Data Usage"
        :amount="dataUsage"
      />
    </div>

    <div class="mt-6 xl:mt-12 relative">
      <machines-list
        :machines="dashboard"
        :is-loading="isLoading"
        @open-machine="openMachine"
      />
    </div>

    <slideover
      v-model:isOpen="isSlideOverOpen"
      @close="closeMachine"
    >
      <template #header>
        <machine-tabs
          v-if="isSlideOverOpen && showMachinesTabs"
          :machines="machines"
          :active-machine-id="activeMachineId"
        />
      </template>
      <machine-show
        v-if="isSlideOverOpen"
        :machine-id="activeMachineId"
        :show-camera-view="showCameraView"
        @machine-not-exists="showMachinesTabs = false"
      />
    </slideover>
  </layout>
</template>

<script>
import { computed, reactive, ref, toRefs, watch, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import http from '@/services/http.js'
import money from '@/helpers/money.js'
import { sortAlpha, formatBytes } from '@/helpers/utils.js'
import Layout from '@/layouts/Default.vue'
import Slideover from '@/components/Slideover.vue'
import MachineShow from '@/views/machines/Show.vue'
import MachineTabs from '@/components/machines/Tabs.vue'
import MachinesList from '@/components/machines/List.vue'
import DashboardCard from '@/components/dashboard/Card.vue'

export default {
  name: 'Dashboard',

  components: {
    Layout,
    Slideover,
    MachineShow,
    MachineTabs,
    MachinesList,
    DashboardCard,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    let pollMachinesListInterval = null
    let pollMachineDashboardInterval = null
    let isSlideOverOpen = ref(false)
    const state = reactive({
      dashboard: [],
      machines: [],
      showMachinesTabs: true,
      activeMachineId: null,
      showCameraView: false,
      isLoading: false,
      dataUsage: null,
    })
    const table = ref()
    const wrapper = ref()


    watch(
      () => state.dashboard,
      () => {
        if (state.dashboard.length === 0) {
          return
        }
      }
    )

    /* Machine sliderover methods */
    const openMachine = (id, activateCameraView = false) => {
      id = parseInt(id)
      state.activeMachineId = id
      state.showCameraView = activateCameraView
      isSlideOverOpen.value = true
      state.showMachinesTabs = true
      router.push({ name: 'Machine', params: { id: id }})
    }

    const closeMachine = () => {
      router.push({ name: 'Dashboard' })
    }

    const getMachines = async () => {
      pollMachinesListInterval = await http.poll(async () => {
        const { data } = await http.get('user/machines')

        state.machines = data.allowed_locations
      }, 30000)

      pollMachineDashboardInterval = http.poll(async () => {
        const response = await Promise.all(state.machines.map((machine) => {
          return http.get(`dashboard/${machine.location_id}`)
        }))

        state.dashboard = response.map(({ data }) => data)

        state.dashboard = sortAlpha(state.dashboard, 'name')
        state.isLoading = false
      }, 10000)

    }

    const stopPolling = () => {
      clearInterval(pollMachinesListInterval)
      pollMachineDashboardInterval ? pollMachineDashboardInterval.then((result) => clearInterval(result)) : null
    }

    /* Routing */
    if (route.params.id) {
      openMachine(route.params.id)
      http.get('user/machines').then(({ data }) => state.machines = data.allowed_locations)
    } else {
      isSlideOverOpen.value = false
      state.isLoading = true

      getMachines()
    }

    watch(
      () => route.params.id,
      (id) => {
        if (id) {
          state.activeMachineId = parseInt(id)
          stopPolling()
        } else if (route.name === 'Dashboard') {
          getMachines()
        }

        isSlideOverOpen.value = !!id
      }
    )

    const getDataUsage = async () => {
      if (state.machines.length > 0) {
        const today = new Date()
        const currentYear = today.getFullYear()
        const currentMonth = today.getMonth()

        const startDate = new Date(currentYear, today.getDate() < 26 ? currentMonth - 1 : currentMonth, 26)
        const endDate = new Date(currentYear, today.getDate() < 26 ? currentMonth : currentMonth + 1, 25)

        const formatDate = (date) => {
          const month = String(date.getMonth() + 1).padStart(2, '0')
          const day = String(date.getDate()).padStart(2, '0')
          return `${date.getFullYear()}-${month}-${day}`
        }

        const locations = state.machines.map(l => `&location_ids=${l.location_id}`).join('');
        const { data } = await http.get(`data-usage/total?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}${locations}`)
        state.dataUsage = formatBytes(data.data_usage_bytes)
      } else {
        state.dataUsage = 'No data'
      }
    }

    watch(
      () => state.machines,
      () => {
        getDataUsage()
      }
    )

    onBeforeUnmount(() => {
      stopPolling()
    })

    /* Statistics */
    const sumAndFormatSalesFromMachinesData = (key) => {
      if (state.dashboard.length === 0) return 0

      return money.format(state.dashboard.reduce((sum, value) => sum + value[key], 0))
    }

    const statistics = reactive({
      iceSales: computed(() => sumAndFormatSalesFromMachinesData('ice_revenue')),
      waterSales: computed(() => sumAndFormatSalesFromMachinesData('water_revenue')),
      totalRevenue: computed(() => sumAndFormatSalesFromMachinesData('total_revenue'))
    })

    return {
      ...toRefs(state),
      isSlideOverOpen,
      openMachine,
      closeMachine,
      statistics,
      table,
      wrapper,
    }
  }
}
</script>
