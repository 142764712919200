const createDebounce = () => {
  let timeout = null

  return function (fnc, delayMs) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fnc()
    }, delayMs || 500)
  }
}

export const debounce = createDebounce()

export const highlightInString = (string, needle) => {
  let len = needle.toLowerCase().length
  let pos = string.toLowerCase().indexOf(needle.toLowerCase())

  if (len === 0 || pos === -1) {
    return string
  }
  else {
    return string.slice(0, pos) + `<mark>${string.slice(pos, pos + len)}</mark>` + string.slice(pos + len)
  }
}

export const sneakCaseToReadable = (value) => {
  return value.split('_')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ')
}

export const sortAlpha = (data, key = null) => {
  if (key) {
    return data.sort((a, b) => a[key].localeCompare(b[key]))
  }

  return data.sort((a, b) => a.localeCompare(b))
}

export const sortAlphaDesc = (data, key = null) => {
  if (key) {
    return data.sort((a, b) => b[key].localeCompare(a[key]))
  }

  return data.sort((a, b) => b.localeCompare(a))
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8)
    return v.toString(16)
  })
}

export const urlize = (text) => {
  // http://, https://, ftp://
  const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim

  // www. sans http:// or https://
  const pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim

  return text
      .replace(urlPattern, '<a class="mx-1 underline" href="$&">$&</a>')
      .replace(pseudoUrlPattern, '$1<a class="mx-1 underline" href="http://$2">$2</a>')
}

export const formatDecimals = (value) => {
  // format number to max 2 decimals
  return Math.round(value*100)/100
}

export const bytesToMB = (bytes) => {
  return bytes / (1000 * 1000)
}

export const formatBytes = (bytes) => {
  return `${bytesToMB(bytes).toFixed(0)} MB`
}

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}
